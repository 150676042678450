<template>
    <div>
        <Toast />

        <router-view :key="$route.path">
            <div class="card" style="min-height: 89vh">
                <Dialog v-model:visible="confirm">
                    <div class="remove-dialog p-5 pt-0">
                        <h3>Você tem certeza que deseja concluir este Plano de ação?</h3>
                    </div>
                    <template #footer>
                        <Button label="Voltar" icon="pi pi-arrow-left" @click="confirm = false" class="p-button-outlined mr-4 b-rounded" />
                        <Button label="Concluir" @click="finishPlan" class="mr-4 b-rounded" />
                    </template>
                </Dialog>

                <header class="flex justify-content-between gap-8 align-items-center px-6 pt-6">
                    <div class="mw-25">
                        <div class="breadcrumb-container flex mb-4 gap-4">
                            <span class="pi pi-home"></span>
                            <span class="pi pi-angle-right pi-angle-right-custom"></span>
                            <p class="text-xl">SESMT</p>
                            <span class="pi pi-angle-right pi-angle-right-custom"></span>
                            <p class="text-xl cursor-pointer">Plano de Ação</p>
                        </div>
                        <div>
                            <h4>{{ this.planoAcao?.customer?.name }} | {{ this.planoAcao?.unidade?.name }}</h4>
                        </div>
                    </div>
                    <Steps :readonly="false" :model="items" aria-label="Form Steps" class="flex-1" />
                </header>

                <div class="flex gap-8 mt-4 px-6">
                    <div class="flex align-items-center gap-2">
                        <i class="pi pi-calendar mt-2" style="font-size: 20px"></i>
                        <p class="mt-3">
                            Data inicial:
                            {{
                                `${this.planoAcao.dataInicial?.split('-')[2]}/${this.planoAcao.dataInicial?.split('-')[1]}/${
                                    this.planoAcao.dataInicial?.split('-')[0]
                                }`
                            }}
                        </p>
                    </div>
                    <div class="flex align-items-center gap-2">
                        <i class="pi pi-calendar mt-2" style="font-size: 20px"></i>
                        <p class="mt-3">
                            Data prevista de conclusão:
                            {{
                                `${this.planoAcao.dataPrevisao?.split('-')[2]}/${this.planoAcao.dataPrevisao?.split('-')[1]}/${
                                    this.planoAcao.dataPrevisao?.split('-')[0]
                                }`
                            }}
                        </p>
                    </div>
                    <div class="flex align-items-center">
                        <p class="mt-3">Objetivo: {{ this.planoAcao.objetivoAcao }}</p>
                    </div>
                    <div class="flex align-items-center">
                        <p class="mt-3">Prioridade: {{ this.planoAcao.prioridadeCausa }}</p>
                    </div>
                    <div class="flex align-items-center">
                        <p class="mt-3">Nome da ação: {{ this.planoAcao.nomeAcao }}</p>
                    </div>
                    <div class="flex align-items-center gap-2" style="margin-left: auto">
                        <p class="mt-3">
                            Status: <span :class="`${this.planoAcao.status}`">{{ this.planoAcao.status?.replace('_', ' ') }}</span>
                        </p>
                    </div>
                </div>
                <div class="px-6 my-4">
                    <Divider />
                </div>
                <div class="flex flex-column align-items-center mt-8">
                    <div class="flex flex-row justify-content-end gap-3" style="width: 95%">
                        <Button
                            class="p-button-outlined"
                            iconPos="left"
                            style="width: 8rem; height: 3rem"
                            @click="this.$router.push('/plano-acao/listar')"
                            :disabled="this.planoAcao.status === 'CONCLUIDO'"
                            ><i class="pi pi-angle-left mx-2" /> Voltar</Button
                        >
                        <Button
                            class="p-button-outlined"
                            style="width: 8rem; height: 3rem"
                            @click="this.confirm = true"
                            :disabled="this.planoAcao.status === 'CONCLUIDO'"
                            >Concluir <i class="pi pi-angle-right ml-2"
                        /></Button>
                    </div>
                    <div class="grid mt-6" style="width: 95%">
                        <div class="col-4">
                            <label for="resultado">Resultado *</label>
                            <Dropdown
                                id="resultado"
                                class="mt-2"
                                optionLabel="label"
                                optionValue="value"
                                v-model="conclusao.resultado"
                                :options="resultados"
                                style="width: 100%"
                                :disabled="this.planoAcao.status === 'CONCLUIDO'"
                            />
                        </div>
                        <div class="col-4">
                            <label for="attInventario">Atualiza inventário de Risco *</label>
                            <Dropdown
                                id="attInventario"
                                class="mt-2"
                                optionLabel="label"
                                optionValue="value"
                                v-model="conclusao.attInventarioRisco"
                                :options="attInventarioRisco"
                                style="width: 100%"
                                :disabled="this.planoAcao.status === 'CONCLUIDO'"
                            />
                        </div>
                        <div class="col-4">
                            <label for="date">Data de conclusão *</label>
                            <Calendar
                                :minDate="new Date(planoAcao.dataInicial)"
                                class="input-data mt-2"
                                id="date"
                                v-model="conclusao.data"
                                showIcon
                                style="width: 100%"
                                :disabled="this.planoAcao.status === 'CONCLUIDO'"
                            />
                        </div>
                        <div class="col-8">
                            <label for="descricao">Descrição do resultado *</label>
                            <Editor
                                id="descricao"
                                v-model="conclusao.descricao"
                                editorStyle="height: 100px;"
                                class="mt-2"
                                ref="editor"
                                :readonly="this.planoAcao.status === 'CONCLUIDO'"
                            >
                                <template v-slot:toolbar>
                                    <span class="ql-formats">
                                        <select class="ql-header" v-tooltip.bottom="'Heading'" :title="'Heading'">
                                            <option value="1">Heading 1</option>
                                            <option value="2">Subheading</option>
                                            <option value="3">Normal</option>
                                        </select>
                                    </span>
                                    <span class="ql-formats">
                                        <select class="ql-font" v-tooltip.bottom="'Font'" :title="'Font'">
                                            <option label="Sans Serif" value="Arial, sans-serif"></option>
                                            <option label="Serif" value="'Georgia', serif"></option>
                                            <option label="Monospace" value="'Courier New', monospace"></option>
                                        </select>
                                    </span>
                                    <span class="ql-formats">
                                        <button v-tooltip.bottom="'Bold'" class="ql-bold"></button>
                                        <button v-tooltip.bottom="'Italic'" class="ql-italic"></button>
                                        <button v-tooltip.bottom="'Underline'" class="ql-underline"></button>
                                    </span>
                                    <span class="ql-formats">
                                        <button v-tooltip.bottom="'Text Color'" class="ql-color"></button>
                                        <button v-tooltip.bottom="'Background Color'" class="ql-background"></button>
                                    </span>
                                    <span class="ql-formats">
                                        <button v-tooltip.bottom="'Numbered List'" class="ql-list" value="ordered"></button>
                                        <button v-tooltip.bottom="'Numbered List'" class="ql-list" value="bullet"></button>
                                        <select class="ql-align" v-tooltip.bottom="'Text Align'" :title="'Text Align'">
                                            <option value=""></option>
                                            <option value="center"></option>
                                            <option value="right"></option>
                                            <option value="justify"></option>
                                        </select>
                                    </span>
                                    <span class="ql-formats">
                                        <button v-tooltip.bottom="'Link'" class="ql-link"></button>
                                        <button v-tooltip.bottom="'Code Block'" class="ql-code-block"></button>
                                    </span>
                                    <span class="ql-formats">
                                        <button v-tooltip.bottom="'Clean'" class="ql-clean"></button>
                                    </span>
                                </template>
                            </Editor>
                        </div>
                    </div>
                </div>
            </div>
        </router-view>
    </div>
</template>

<script>
import PlanoAcaoService from '../../services/planoAcaoService';

export default {
    data() {
        return {
            planoAcao: {},
            conclusao: {},
            confirm: false,
            resultados: [
                { value: 'EFICAZ', label: 'Eficaz' },
                { value: 'NAO_EFICAZ', label: 'Não eficaz' }
            ],
            attInventarioRisco: [
                { value: true, label: 'Sim' },
                { value: false, label: 'Não' }
            ],
            items: [
                {
                    label: 'Plano Ação',
                    to: `/plano-acao/${this.$route.params.id || 'criar'}`
                },
                {
                    label: 'Acompanhamento',
                    to: `/plano-acao/${this.$route.params.id}/acompanhamento`
                },
                {
                    label: 'Anexos',
                    to: `/plano-acao/${this.$route.params.id}/anexos`
                },
                {
                    label: 'Conclusão',
                    to: `/plano-acao/${this.$route.params.id}/conclusao`
                }
            ]
        };
    },

    mounted() {
        this.getData();
    },

    methods: {
        async getData() {
            this.$service = new PlanoAcaoService();
            const responsePlanoAcao = await this.$service.get('', '', this.$route.params.id);
            this.planoAcao = responsePlanoAcao.data;

            if (responsePlanoAcao.data.status === 'CONCLUIDO') {
                this.conclusao.data = `${responsePlanoAcao.data.dataConclusao.split('-')[2]}/${responsePlanoAcao.data.dataConclusao.split('-')[1]}/${
                    responsePlanoAcao.data.dataConclusao.split('-')[0]
                }`;
                this.conclusao.descricao = responsePlanoAcao.data.descricaoConclusao;
                this.conclusao.resultado = responsePlanoAcao.data.resultado;
                this.conclusao.attInventarioRisco = responsePlanoAcao.data.attInventario;
            }
        },
        getEditorValue() {
            const value = this.$refs.editor.quill.root.innerText;
            return value;
        },
        async finishPlan() {
            if (this.planoAcao.tipo === 'EPI' && !this.planoAcao.ca.length) {
                return this.$toast.add({
                    severity: 'error',
                    summary: 'Obrigatório cadastro de ao menos um CA para concluir plano de ação do tipo EPI.',
                    life: 3000
                });
            }

            if (!this.conclusao.data || !this.conclusao.descricao || !this.conclusao.resultado || this.conclusao.attInventarioRisco === undefined) {
                this.confirm = false;
                return this.$toast.add({
                    severity: 'error',
                    summary: 'Todos os campos são obrigatórios para a conclusão do plano.',
                    life: 3000
                });
            }

            const dataToSave = {
                dataConclusao: this.conclusao.data,
                descricaoConclusao: this.getEditorValue(),
                resultado: this.conclusao.resultado,
                attInventario: this.conclusao.attInventarioRisco,
                status: 'CONCLUIDO'
            };

            await this.$service.patch(this.$route.params.id, dataToSave);

            this.$toast.add({
                severity: 'success',
                summary: 'Plano de Ação concluido.',
                life: 3000
            });

            this.confirm = false;
            this.$router.push('/plano-acao/listar');
        }
    }
};
</script>

<style scoped>
.message {
    width: 452px;
}
.pi-home,
.pi-angle-right-custom {
    font-size: 2rem;
}

.PENDENTE {
    background-color: #f7ffc6;
    color: #504d05;
}

.EM_ANDAMENTO {
    background-color: #c3f3f6;
    color: #0d0540;
}

.CONCLUIDO {
    background-color: #c3f6d9;
    color: #0e4005;
}

.EXCLUIDO {
    background-color: #ffd3d3;
    color: #700d06;
}
</style>
